import React from "react";
import { HomeBackground } from "../../components/Background";
import { Body } from "../../components/Body";
import { Title } from "../../components/Title";
import { Divider } from "../../components/Divider";
import Text from "../../components/Text";
import { data } from "../../data/HomePageData";
import { Seo } from "../../components/Seo";

const Index = () => (
  <>
    <Seo />
    <HomeBackground />
    <Body>
      <Title text="홈" className="text-red-hi" />
      <Divider />
      <div className="w-full my-3 px-3 md:px-6 max-w-3xl text-gray-common text-lg mb-5">
        <Text.Body>
          이 공략은 비공식이며 아래의 내용들을 포함하고 있습니다.
        </Text.Body>
        <ul className="list-square list-inside">
          <Text.Body>
            <li>모든 루트 공략 (이터와 윌리엄 루트 포함)</li>
            <li>모든 앨범</li>
            <li>모든 DLC (We never forget you 포함)</li>
          </Text.Body>
        </ul>
        <Text.Body>
          <br />
          좌측의 탐색바를 이용하실 수 있습니다.
          <br />
          오류, 오타 혹은 건의사항이 있으시다면{" "}
          <a
            href="mailto:twinklepurple1117@gmail.com?subject=[러브언홀릭 공략]"
            className="underline"
          >
            이메일
          </a>
          로 연락 주세요.
          <br />
          <br />
          {"즐겁게 플레이하시길 바랍니다 :)"}
          <br />
          <br />
          <br />
          <span className="text-xl">프리티비지 공식:</span>
          <br />
        </Text.Body>
        <ul className="list-disc list-inside underline">
          <Text.Body>
            {data.map(({ ko, link }) => (
              <li key={link} className="my-1">
                <a href={link}>{ko}</a>
              </li>
            ))}
          </Text.Body>
        </ul>
      </div>
    </Body>
  </>
);

export default Index;
